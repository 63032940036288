import { EnvEnum } from "@/src/enums/common";

export type FeatureToggleEnvType = Record<EnvEnum, boolean>;
export type FeatureToggleType = boolean | FeatureToggleEnvType;

export enum FeatureToggle {
  EnableApiDebug = "enable-api-debug",
  ServerLogger = "server-logger",
  EnableUiDebug = "enable-ui-debug",
  ActivityHistory = "activity-history",
  AttributeOptionsEditor = "attribute-options-editor",
  EnrichmentApiSkuId = "enrichment-api-sku-id",
  CustomCategoryInAttributeEnabled = "custom-category-in-attribute",
  AllowDeleteSkuIDInPDP = "allow-delete-skuid-in-pdp",
  PdpSources = "pdp-sources",
}

export const featureToggleConfig: Record<FeatureToggle, FeatureToggleType> = {
  [FeatureToggle.EnableApiDebug]: {
    [EnvEnum.DEV]: true,
    [EnvEnum.QA]: true,
    [EnvEnum.STAGING]: true,
    [EnvEnum.PRODUCTION]: false,
  },
  [FeatureToggle.ServerLogger]: {
    [EnvEnum.DEV]: false,
    [EnvEnum.QA]: true,
    [EnvEnum.STAGING]: true,
    [EnvEnum.PRODUCTION]: true,
  },
  [FeatureToggle.EnableUiDebug]: {
    [EnvEnum.DEV]: false,
    [EnvEnum.QA]: false,
    [EnvEnum.STAGING]: false,
    [EnvEnum.PRODUCTION]: false,
  },
  [FeatureToggle.ActivityHistory]: {
    // BE API not ready even before v2. Hence will remain OFF.
    [EnvEnum.DEV]: false,
    [EnvEnum.QA]: false,
    [EnvEnum.STAGING]: false,
    [EnvEnum.PRODUCTION]: false,
  },
  [FeatureToggle.AttributeOptionsEditor]: {
    [EnvEnum.DEV]: true,
    [EnvEnum.QA]: true,
    [EnvEnum.STAGING]: true,
    [EnvEnum.PRODUCTION]: true,
  },
  [FeatureToggle.EnrichmentApiSkuId]: {
    [EnvEnum.DEV]: true,
    [EnvEnum.QA]: true,
    [EnvEnum.STAGING]: true,
    [EnvEnum.PRODUCTION]: true,
  },
  [FeatureToggle.CustomCategoryInAttributeEnabled]: {
    [EnvEnum.DEV]: true,
    [EnvEnum.QA]: true,
    [EnvEnum.STAGING]: false,
    [EnvEnum.PRODUCTION]: false,
  },
  [FeatureToggle.AllowDeleteSkuIDInPDP]: {
    [EnvEnum.DEV]: true,
    [EnvEnum.QA]: true,
    [EnvEnum.STAGING]: true,
    [EnvEnum.PRODUCTION]: true,
  },
  [FeatureToggle.PdpSources]: {
    [EnvEnum.DEV]: false,
    [EnvEnum.QA]: false,
    [EnvEnum.STAGING]: false,
    [EnvEnum.PRODUCTION]: false,
  },
};
