import useAppContext from "@/src/components/appContext/useAppContext";
import { FeatureToggle } from "@/src/constants/featureFlags";

export function useFeatureFlag() {
  const { ctx } = useAppContext();

  const isEnabled = (key: FeatureToggle) =>
    ctx?.featureFlags ? ctx.featureFlags[key] : false;

  return {
    isApiDebugEnabled: isEnabled(FeatureToggle.EnableApiDebug),
    isUIDebugEnabled: isEnabled(FeatureToggle.EnableUiDebug),
    isActivityHistoryEnabled: isEnabled(FeatureToggle.ActivityHistory),
    isAttributeOptionsEditorEnabled: isEnabled(
      FeatureToggle.AttributeOptionsEditor
    ),
    isEnrichmentApiSkuIdEnabled: isEnabled(FeatureToggle.EnrichmentApiSkuId),
    isCustomCategoryInAttributeEnabled: isEnabled(
      FeatureToggle.CustomCategoryInAttributeEnabled
    ),
    isAllowDeleteSkuIdInPdPEnabled: isEnabled(
      FeatureToggle.AllowDeleteSkuIDInPDP
    ),
    isPdpSourcesEnabled: isEnabled(FeatureToggle.PdpSources),
  };
}
